export default {
  proxy_path: "https://wuixvuvzdi.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://y7nrvey8r4.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyBthLqIniCp2bAvxhf4mvKwBT0I6ENtM7M",
  main_company_term_id: "6",
  crm_base_url: "staging.ozminerals.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.ozminerals.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.ozminerals.forwoodsafety.com",
    APP_CLIENT_ID: "11e4d6ulqnper4ij1nh24m5vun",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://ecsaxxnr3i.execute-api.ap-southeast-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.ozminerals.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
